'use strict'

import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state';

const Kv = class {
  constructor() {
    $('.kv').height(window.innerHeight + 'px');
  }
};
export default Kv;
