import './polyfill'

import Header from './modules/Header';
import HeaderNav from './modules/HeaderNav';
import Inview from './modules/Inview';
import Link from './modules/Link';
import Kv from './modules/Kv';

import state from './util/state';

state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  // new Header();
  // new HeaderNav();
  // new Gmap();
  new Link('.js-link');
  //
  // if(document.querySelector('.js-swiper')) {
  //   new Swiper('.js-swiper');
  // }
  // if(document.querySelector('.js-tab')) {
  //   new Tab('.js-tab');
  // }
  if(document.querySelector('.kv') && state.dev === 'sp') {
    new Kv();
  }
  //
  // new Inview();

  document.querySelector('#wrap').classList.add('ready');
});

window.onload = function(){
  // Load functions
  if(state.dev === 'pc') {
    // new Scroll();
  }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';
  if(state.dev === 'sp') {
    if(document.querySelector('.kv')) {
      new Kv();
    }
  }
}, 500));
